import { AppLabel } from "~/types";

import zillion from "./zillion/zillion";
import AppStorage from "~/services/storage";

type AppLabels = {
  [key: string]: AppLabel;
};

const labels: AppLabels = {
  default: zillion,
  zillion: zillion,
};

const activeTheme = AppStorage.getTheme();

const label = activeTheme ? labels[activeTheme] : labels.default;

if (label === undefined) {
  throw new Error("Active theme is not defined");
}

export default label;
